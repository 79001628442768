import isPropValid from '@emotion/is-prop-valid'
import Link from '../link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	isPropValid(prop) && !['type', 'component', 'items', 'maxItemsForColumn', 'parentMaxItemsForColumn'].includes(prop)

const DropDownWrapper = styled('div', { shouldForwardProp })`
	padding: 38px var(--spacing-4);
	display: block;
	border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
	border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
	&.no-padding {
		padding: 0;
	}
	&.cover-images {
		> div {
			> .DropDrownList-root {
				grid-template-columns: revert;
				grid-auto-flow: column-group;
				grid-auto-columns: 1fr;

				> .DropDrownList-item {
					&:nth-of-type(even) {
						grid-column: attr(position);
						grid-row: 2;
						margin-top: 20px;
						padding: 0 30px;
					}
				}
			}
		}
	}
`
const DropDrownList = styled(List, {
	shouldForwardProp,
})`
	position: revert;
	max-width: var(--layout-max-width);
	margin: 0 auto;
	display: grid;
	grid-template-columns: ${({ countItems }) => `repeat(${countItems}, 1fr)`};
	grid-template-rows: 1fr;
	grid-gap: 2px;
	align-items: flex-start;
	padding: 0;
	width: 100%;
	.DropDrownList-item {
		[class*='banner-hover-trigger'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			&._show {
				visibility: visible;
				opacity: 1;
			}
		}
		[class*='default-hover-trigger'] {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			&._hide {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
	&.parent_column-layout {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(${({ countItems }) => countItems ?? 1}, max-content);
		grid-auto-flow: column;
		justify-content: flex-start;
		.hasBanner {
			grid-column: ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
			display: flex;
			justify-content: flex-end;
			grid-row-end: span ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
		}
		.MuiListItem-root {
			&:nth-of-type(n
					+ ${({ parentMaxItemsForColumn }) =>
						parentMaxItemsForColumn >= 1 ? parentMaxItemsForColumn + 1 : -1}):not(.hasBanner) {
				margin-left: 50px;
				grid-column: 2;
			}
		}
	}
`

const DropDrownListItem = styled(ListItem, {
	shouldForwardProp,
})`
	display: block;
	padding: 0;
	padding-left: ${({ type }) => (type === 'cms_block' ? '0px' : '0px')};
	& .MuiList-root {
		display: block;
		padding: 0;
		& .MuiListItem-root {
			padding-left: 0;
		}
	}
	&.menu-item-current {
		> a {
			&:hover {
				text-decoration: none;
			}
		}
		> a:after {
			width: 100%;
		}
		&:hover {
			> a:after {
				background-color: ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
	&.view-all {
		margin-top: 10px;
	}
`

const NavLinkStyled = styled(Link)`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	line-height: 28px;
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	margin-bottom: ${({ ...props }) => (props.variant === 'h4' ? '10px' : undefined)};
	position: relative;
	text-decoration: none;
	&:not(.no-link):hover {
		color: ${({ theme }) => theme.palette.primary.main};
		&:after {
			width: 100%;
		}
	}
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: ${({ theme }) => theme.palette.primary.main};
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
		position: absolute;
		bottom: 5px;
	}
	&.last {
		margin-bottom: 18px;
	}
	&.no-link {
		cursor: default;
		&:after {
			display: none;
		}
	}
`

const AdditionalBlock = styled('div')`
	width: 100%;
	padding: 12px 68px;
	text-align: center;
	border-top: 1px solid ${({ theme }) => theme.palette.grey.light};
	& p {
		margin: 0;
	}
`
const ChildrenWrapper = styled('div')`
	display: flex;
	gap: 15px;
`

export { DropDownWrapper, DropDrownList, DropDrownListItem, NavLinkStyled, AdditionalBlock, ChildrenWrapper }
